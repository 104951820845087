export function app() {
	const appEl = document.getElementById( 'my-app' );

	if ( ! appEl ) {
		return;
	}

	const buttonEl = appEl.querySelector( 'button' );
	if ( ! buttonEl ) {
		return;
	}

	const countEl = buttonEl.querySelector( 'span' );
	if ( ! countEl ) {
		return;
	}

	let currentCount = parseInt( countEl.innerText );

	buttonEl.addEventListener( 'click', function( event ) {
		event.preventDefault();
		currentCount += 1;

		countEl.innerText = currentCount;
	} );
}
